html {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;

  --app-color-white: #FFFFFF;
  --app-color-gray: #F0F0F0;
  --app-color-black: #000000;
  --app-color-purple: #7634F6;
  --app-color-teal: #00E5B9;
  --app-color-blue: #001769;
  --app-color-orange: #FF5926;
  --app-color-pink: #D52E66;
  --subpage-header-height: 61px;
  --logo-square-dimension: 90px;

  @media screen and (max-width: 740px) {
    --subpage-header-height: 50px;
    --logo-square-dimension: 73px;
  }

  @media screen and (max-width: 640px) {
    --subpage-header-height: 40px;
    --logo-square-dimension: 58px;
  }
}

main {
  width: 100vw;
  overflow-x: clip;
}

body, header, main, button, textarea, input {
  font-family: 'Montserrat', sans-serif;
}

body, header, main {
  display: flex;
  flex-direction: column;
  color: #00146E;
}

.page-content, section {
  width: 98vw;
  max-width: 1210px;
  margin-left: auto;
  margin-right: auto;

  &.padded {
    width: 80vw;
  }
}



header {
  &.homepage {
    position: relative;
    display: grid;
    grid-template-areas: 
      'hero navz'
      'hero logoz'
      'hero textz';
    grid-template-columns: minmax(100px, 1fr) minmax(440px, 1fr);
    column-gap: 8vw;
    row-gap: 3rem;
    margin-bottom: 4rem;

    @media screen and (max-width: 960px) {
      column-gap: 5vw;
      width: 100%;
    }

    #mobile-nav {
      display: flex;
    }
    .mobile-nav-wrapper {
      visibility: hidden;
      position: fixed;
      width: 100%;
      background-color: white;
      z-index: 9;
      filter: none;
      transition: filter 0.3s;
      margin-top: 0;

      &.floating {
        filter: drop-shadow(0px 1px 4px rgba(0,0,0,0.22));
      }
    }

    @media screen and (max-width: 740px) {
      .mobile-nav-wrapper {
        visibility: visible;
      }
      grid-template-areas: 
        'logoz'
        'textz'
        'hero'
        'navz';
      grid-template-columns: 1fr;
      gap: 2rem;
      margin-bottom: -3.6rem;

      .hide-mobile {
        display: none;
      }
    }
  
    .hero {
      grid-area: hero;
      overflow: visible;
      position: relative;
      margin-right: 1rem;
      img {
        position: absolute;
        margin: auto;
        right: 0;
        top: 0;
        bottom: 0;
        width: 140%;
        min-width: 550px;
        max-width: 1000px;
        height: auto;
        transition: all 500ms;

      }
      @media screen and (min-width: 1400px) {
        img {
          width: calc(130% + 10vw);
        }
      }

      @media screen and (max-width: 740px) {
        margin-right: 0;
  
        img {
          width: 90%;
          min-width: unset;
          position: relative;
          transform: scale(1) translateX(-22%) translateY(-1rem);
        }
      }
  
      @media screen and (max-width: 540px) {
        img {
          transform: scale(1.15) translateX(-13%);
        }
      }
    }
  
    nav {
      grid-area: navz;
      margin-top: 2rem;
      ul {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        gap: 1.2rem;
        row-gap: 0.6rem;
        margin: 0;
        padding: 0;
      }
    }
  
    .logo {
      grid-area: logoz;
      margin-right: 20%;
      max-width: 420px;
  
      a {
        display: block;
        img {
          width: 100%;
          height: auto;
        }
      }
      
      .menu-icon {
        display: none;
      }
    }
  
    .text {
      grid-area: textz;
      font-size: 1.2rem;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 20%;

      @media screen and (max-width: 740px) {
        margin: 5.4rem auto 2rem;
        max-width: 440px;
      }
      @media screen and (max-width: 540px) {
        margin-left: 8%;
        margin-right: 8%;
      }
    }
  }

  &.subpage {
    position: relative;
    padding-top: var(--subpage-header-height);
    overflow-x: hidden;

    @media screen and (max-width: 540px) {
      .top-bg-fill {
        padding-top: 9rem;
        width: 100%;
        position: absolute;
      }
    }

    .svg-bg {
      position: absolute;
      width: 100%;
      max-height: 390px;
      z-index: -1;

      @media screen and (max-width: 540px) {
        padding-top: 8.9rem;
      }
    }

    h1 {
      font-size: 3.4rem;
      line-height: 1.2em;
      width: 100%;

      @media screen and (max-width: 1000px) {
        font-size: 3rem;
      }
      @media screen and (max-width: 850px) {
        font-size: 2.5rem;
      }
      @media screen and (max-width: 700px) {
        font-size: 2rem;
      }
      @media screen and (max-width: 540px) {
        text-align: center;
        font-size: 2.2rem;
        padding-top: 2rem;
      }
    }

    div.header-content {
      padding: 1rem 0 5.6rem;
      display: flex; 
      flex-direction: row;
      gap: 1rem;
      width: 88vw;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      z-index: 1;

      >* {
        flex-basis: 0;
        flex-grow: 1;
      }

      .svg-wrapper {
        display: flex;
        align-items: center;
        height: 25vw;
        max-height: 340px;
      }

      img {
        height: 100%;
        transform: scale(1.2);
        transform-origin: left;
      }

      @media screen and (max-width: 540px) {
        flex-direction: column;
        padding-bottom: 3rem;

        img {
          width: 50vw;
          transform: scale(1.5);
          transform-origin: center;
        }
      }
    }
  }
}

nav {
  &.subpage {
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 9;
    filter: none;
    transition: filter 0.3s;
  }

  ul {
    list-style-type: none;
    display: flex;
    gap: 1.2rem;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 640px) {
      gap: 0.6rem;
    }
  }
  a {
    color: #6C41F0;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.85rem;

    &:focus, &:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: 740px) {
      font-size: 0.78rem;
    }

    @media screen and (max-width: 640px) {
      font-size: 0.7rem;
      gap: 0.2rem;
    }
  }

  &.floating {
    filter: drop-shadow(0px 1px 4px rgba(0,0,0,0.22));
  }
  
  #mobile-nav {
    display: none;
  }

  #desktop-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .logo-wrapper {
      height: var(--subpage-header-height);
      position: relative;
      width: var(--logo-square-dimension);
  
      svg {
        position: absolute;
        top: 0;
        left: 0;
        height: var(--logo-square-dimension);
      }
    }
  }

  @media screen and (max-width: 525px) {
    #mobile-nav {
      display: flex;
    }
    #desktop-nav {
      display: none;
    }
  }
}

/* MobileNav.js */
#mobile-nav, #mobile-nav-overlay .top-items, #contact-modal-overlay .top-items {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0.75rem;

  .logo-wrapper {
    svg {
      width: 40vw;
      min-width: 160px;
    }
  }
  .logo-wrapper, .hamburger-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hamburger-icon {
    margin-top: 1px;
  }

  >:nth-child(3) {
    padding: 0.2rem;
    align-self: flex-start;
  }
  >:nth-child(1), >:nth-child(3) {
    width: 34px;
  }
}

/* MobileNavOverlay.js */
#mobile-nav-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  overflow: scroll;
  visibility: hidden;

  .top-items {
    display: flex;
  }

  nav {
    padding-left: 16vw;
    padding-right: 60px;

    ul {
      padding-top: 3rem;
      flex-direction: column;
      gap: 2.7rem;
    }
    a {
      color: inherit;
      font-size: 1.8rem;
      line-height: 1.15em;
      font-weight: 500;
    }
  }
}

main {
  section {
    overflow: visible;
    position: relative;
  }

  h2 {
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 4.4rem;
  }
}

footer {
  background-color: #001769;
  color: #ffffff;
  
  .page-content {
    min-height: 500px;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 2rem 0;
    width: 92vw;
    gap: 3rem;

    .left-footer {
      flex: 1 1 0; 
    }
    .footer-nav {
      flex: 1.8 1 0; 
    }
  }

  .pet-image {
    position: absolute;
  }
  

  .left-footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    a.logo {
      max-width: 250px;
      margin-bottom: 1rem
    }
    span {
      max-width: 33ch;
    }
  }
  .footer-contact {
    display: flex;
    gap: 1.5rem;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
    gap: 1.5rem;
    justify-content: flex-end;
    a, .no-button-styling {
      color: inherit;
      text-decoration: none;
    }

  }
  @media screen and (max-width: 940px) {
    .page-content {
      padding-bottom: 90px;
    }
  }
  @media screen and (max-width: 740px) {
    overflow-x: hidden;
    .page-content {
      padding-bottom: 150px;
    }
  }
  @media screen and (max-width: 540px) {
    .page-content {
      padding-bottom: 260px;
    }
    .page-content {
      flex-direction: column;
      gap: 3rem;
    }
    ul {
      flex-direction: column;
    }
    
  }
}

.sandy {
  bottom: 0%;
  right: 5%;
  height: 45%;
}
.pebble {
  bottom: 0%;
  right: 38%;
  height: 30%;
}
.lola {
  bottom: 40px;
  right: 22%;
  height: 42%;
}
.kirby {
  bottom: 0%;
  right: 10%;
  height: 20%;
}
.valkyrie {
  bottom: 0%;
  right: 30%;
  height: 420px;
}
.buddy {
  bottom: 0%;
  right: 12%;
  height: 190px;
}
@media screen and (max-width: 940px) {
  .sandy {}
  .pebble {
    right: 50%;
  }
  .lola {}
  .kirby {}
  .valkyrie {
    right: 18%;
  }
  .buddy {
    right: 4%;
  }
}
@media screen and (max-width: 740px) {
  .sandy {
    height: 37%;
    right: 0;
  }
  .pebble {
    left: auto;
    right: 55%;
    height: 20%;
  }
  .lola {
    height: 25%;
  }
  .kirby {
    height: 12%;
  }
  .valkyrie {
    right: -2%;
  }
  .buddy {
    right: 45%;
  }
}
@media screen and (max-width: 540px) {
  .sandy {
    height: 24%;
    right: -12%;
  }
  .pebble {
    height: 13%;
    left: auto;
    right: 55%;
  }
  .lola {
  }
  .kirby {
  }
  .valkyrie {
    height: 370px;
  }
  .buddy {
    right: 55%;
    height: 150px;
  }
}
@media screen and (max-width: 400px) {
  .valkyrie {
    height: 300px;
  }
  .buddy {
    right: 55%;
    height: 100px;
  }
}

img {
  --icon-size: 26px;
  &.icon {
    height: var(--icon-size);
    width: var(--icon-size);
  }
}

a {
  &.link-with-icon {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }
}

p {
  max-width: 65ch !important;
  margin-left: auto;
  margin-right: auto;
}

.subheading, .heading {
  margin-bottom: 1.2em;
  color: #7634F6;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.095em;
  font-size: 1.6rem;
}

/* ThreeTiles.js */
.tile-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  cursor: pointer;
  
  >a {
    display: flex;
    flex-direction: column;
    padding: 1.4rem 2.2rem;

    filter: drop-shadow(0 3px 6px #D2CBE3);
    background: white;
    border-radius: 10px;
    flex: 1 1 0;
    transition: all .2s;

    &:hover {
      transform: scale(1.075);
    }

    .tagline {
      text-align: center;
      font-weight: 800;
      font-style: italic;
      margin-bottom: 3rem;
    }

    .app-icon {
      margin-bottom: 2rem;
    }
  }

}

@media screen and (max-width: 1000px) {
  .three-tiles {
    padding: 0.5rem 3vw 2.4rem;
    overflow-x: scroll;
    
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .tile-wrapper {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    
    >a {
      width: 33vw;
    }
  }
}

@media screen and (max-width: 800px) {
  .tile-wrapper {
    gap: 1.2rem;
    >a {
      width: 42vw;
    }
  }
}

@media screen and (max-width: 660px) {
  .tile-wrapper>a {
    width: 57vw;
    padding: 1rem 1.4rem;
  }
}
@media screen and (max-width: 550px) {
  .tile-wrapper>a {
    width: 70vw;
    padding: 1rem 1.4rem;
  }
}

.app-icon {
  --offset-x: 28px;
  --offset-y: 14px;

  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #00E9B6;
  margin-right: var(--offset-x);
  margin-bottom: var(--offset-y);

  img {
    max-width: 100%;
    max-height: 100%;
    transform: translateX(var(--offset-x)) translateY(var(--offset-y));
  }
}

.app-background-blob {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.no-link-styling {
  text-decoration: unset;
  color: unset;
}

.no-button-styling {
  background: none;
  color: inherit;
  border: none;
  border-radius: 0;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;

  &:focus-visible {
    outline: 3px solid var(--app-color-teal);
    outline-offset: 3px;
  }
}

.fullbleed {
  width: 100%;
}

.bg-blue {
  background: var(--app-color-blue);
}

.bg-purple {
  background: var(--app-color-purple);
}

.text-blue {
  color: var(--app-color-blue);
}

.text-purple {
  color: var(--app-color-purple);
}

.text-white {
  color: var(--app-color-white);
}

button {
  --button-border-width: 0px;
  background: var(--app-color-blue);
  color: white;
  border: none;
  padding: calc(1.2rem + var(--button-border-width)) calc(2.6rem + var(--button-border-width));
  font-size: 1.2rem;
  border-radius: 10px;
  font-weight: 800;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inverted-colors {
  h2, h3, p {
    color: white;
  }
}

/* CollaborateYourWay.js */
@media screen and (max-width: 1000px) {
  .tablet-fullbleed {
    width: 100vw;
  }
}
@media screen and (max-width: 660px) {
  .mobile-fullbleed {
    width: 100vw;
  }
}
.collaborate-your-way {
  .hide-mobile {
    display: inherit;
  }
  .show-mobile {
    display: none;
  }
  
  @media screen and (max-width: 660px) {
    .hide-mobile {
      display: none;
    }
    .show-mobile {
      display: inherit;
    }
    .mobile-fullbleed {
      width: 100vw;
    }
  }

  --border-width: 3px;
  --border-color: var(--app-color-teal);
  --border-radius: 0.8rem;
  .desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    position: relative;
    overflow: visible;
    overflow-x: clip;
    padding: 0 3rem 0 1rem;
    margin-bottom: 2rem;
    >div {
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  .mobile-wrapper {
    overflow-x: scroll;
    
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    >div {
      display: inline-grid;
      grid-template-columns: 1fr 1fr;
      gap: 10vw;
      margin: 0 7vw;
    }

    .content-wrapper {
      width: 80vw;
      .content {
        padding: 1.6rem 0.75rem 1.6rem 3rem;

        .subheading {
          font-size: 1.1rem;
        }

        >div .app-icon {
          right: calc(100%);

          img {
            --offset-x: 16px
          }
        } 
      }
    }
  }

  .selectors-wrapper {
    display: flex;
    flex-direction: column;
    
    button {
      --button-padding: 2rem;
      
      text-align: left;
      margin: 1rem 2rem;
      padding: var(--button-padding);
      border-radius: var(--border-radius);
      
      
      &.selected, &:hover {
        border-width: var(--border-width);
        border-color: var(--border-color); 
        padding: calc(var(--button-padding) - var(--border-width));
      }
      &:hover {
        border-style: dashed;
      }
      &.selected {
        border-style: solid;
      }
    }
  }

  .content-wrapper {
    .content {
      padding: 2rem 1rem 2rem 4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.5rem;
      transition: all 0.5s;

      border: var(--border-width) solid var(--border-color);
      border-radius: var(--border-radius);
  
      >div {
        position: relative;
        display: flex;
        flex-direction: column;
  
        .app-icon {
          position: absolute;
          top: -4px;
          right: calc(100% + 1rem);
        }

        .subheading {
          margin-bottom: 0;
        }

        p {
          margin: 0.6rem 0 0.1rem;
        }

        a {
          display: flex;
          gap: 0.4rem;
          justify-content: flex-end;
          margin-left: auto;
          margin-right: 2rem;
          font-size: 0.9em;
          font-weight: 600;
          color: #6C41F0;
        }
      }
    }
  }

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible;
    pointer-events: none;

    path {
      stroke: var(--border-color);
      stroke-width: var(--border-width);
      fill: transparent;
    }
  }
}

/* LetsConnectEntrypoint.js */
.lets-connect-entrypoint {
  h2 {
    margin: 4rem 0 2rem;
  }

  button {
    margin: 1rem 0 3rem;
  }
}

/* LetsConnectModal.js */
#contact-modal-overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255,255,255,1);
  position: fixed;
  z-index: 10;
  overflow: scroll;
  visibility: hidden;

  .top-items {
    display: flex;

    .menu-icon img {
      fill: var(--app-color-purple);
    }
  }
  
  .content-wrapper {
    border: 3px solid var(--app-color-teal);
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 2rem 2.5rem 2.5rem;
    display: flex;
    flex-direction: column;
    width: calc(96vw - 5rem);
    max-width: 700px;
    margin: 4rem auto;

    .close-button {
      align-self: flex-end;
      color: #6C41F0;
      text-decoration: underline;
    }

    h1 {
      font-size: 3.2rem;
      line-height: 1.1em;
      color: var(--app-color-purple);
      text-align: center;
      margin: 0 auto 1rem;
    }

    p {
      max-width: 50ch;
      text-align: center;
      margin: 1rem auto 1.5rem;
    }

    @media screen and (max-width: 600px) {
      border: none;
      width: auto;
      margin-top: 0;
    }
  }

  .success-item {
    align-self: center;
    &img {
      max-width: 200px;
    }
  }

  .show-mobile {
    display: none;
    visibility: hidden;
  }
  .hide-mobile {
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (max-width: 740px) {
    .show-mobile {
      display: flex;
      visibility: unset;
    }
    .hide-mobile {
      display: none;
      visibility: hidden;
    }
  }
}

/* AppContactForm.js */
form.app-contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  .input-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 2.5rem;

    .input-field {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      padding-bottom: 1.5rem;
      position: relative;

      &.span2 {
        grid-column: span 2;
      }
    }
    
    @media screen and (max-width: 550px) {
      grid-template-columns: 1fr;
      
      .input-field {
        &.span2 {
          grid-column: span 1;
        }
      }
    }
  }

  label {
    font-size: 0.72rem;
    text-transform: uppercase;
    font-weight: 800;
  }

  --input-border-color: #D1D1D1;
  --input-error-color: #FF0000;
  input, textarea {
    border: 3px solid var(--input-border-color);
    padding: 1rem;
    border-radius: 10px;
    font-size: 1rem;
    resize: none;
  
    &:active, &:focus {
      border-color: var(--app-color-purple);
      box-shadow: none;
    }
  
    &.invalid {
      border-color: var(--input-error-color);
    }
    &:disabled {
      background-color: var(--input-border-color);
      cursor: not-allowed;
    }
  }
  .error-message, .form-error-message {
    color: var(--input-error-color);
    font-style: italic;
    font-weight: 400;
  }
  .error-message {
    font-size: 0.7rem;
    position: absolute;
    bottom: 0;
  }
  .form-error-message {
    text-align: center;
    margin: 2rem 0 0;
  }

  button {
    margin: 2rem auto 0;
  }
}

textarea {
  min-height: 5em;
  font-weight: 400;
}

/* ABItems.js */
.ab-items {
  padding-bottom: 2rem;
  max-width: 900px;

  div.heading-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    margin-bottom: 3.2rem;
    h2  {
      margin-top: 5.5rem;
      margin-bottom: 0;
    }
  }

}

/* ABItem.js */
.ab-item {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr minmax(120px, 1fr) 1fr;
  grid-template-rows: auto;
  gap: 3rem;
  margin-bottom: 3rem;

  .line {
    height: 4px;
    background-color: var(--app-color-teal);
    position: relative;
  }

  .triangle {
    height: 24px;
    position: absolute;
    left: 0;
    top: -10px;
    transform: translateX(0);
    // transition: .3s all;
  }
  .circle {
    height: 13px;
    width: 13px;
    border-radius: 7px;
    background-color: var(--app-color-orange);
    position: absolute;
    top: -5px;
    right: -7px;
  }
  .logo-wrapper {
    width: 46px;
    height: 46px;
    position: absolute;
    left: calc(50% - 23px);
    top: -22px;
  }

  .start {
    text-align: right;
    font-weight: 600;
  }
  .end {
    text-align: left;
    font-weight: 700;
  }

  @media screen and (max-width: 540px) {
    grid-template-columns: 1fr;
    gap: 1.3rem;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    border: 3px solid var(--app-color-teal);
    margin: 0 0.6rem 2rem;

    &.no-responsive {
      grid-template-columns: 1fr 4fr 1fr;
      border: none;
    }

    .start, .end {
      text-align: center;
    }

    .hide-mobile {
      display: none;
    }
  }
}

/* HowWeDoIt.js */
.how-we-do-it {
  margin-bottom: 5.6rem;

  h2 {
    display: inline-flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1.4rem;
    margin-top: 4rem;

    .app-icon {
      width: 36px;
      height: 36px;
    }
  }

  .tiles {
    display: flex;
    justify-content: center;
    gap: 3%;
    
    @media screen and (max-width: 540px) {
      align-items: stretch;
      flex-direction: column;
      gap: 1.5rem;
      margin: 0 0.6rem;
    }

    >div {
      flex: 1 1 0;
      border: 3px solid var(--app-color-teal);
      border-radius: 10px;
      padding: 2rem;
      max-width: 400px;
    }

    h3 {
      color: var(--app-color-pink);
      text-transform: uppercase;
      font-size: 1.4rem;
      margin: 0;
    }
  }
}

/* StandUp.js */
.n-standup {
  width: 92vw;
  max-width: 1060px;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 6rem;
  
  >div {
    flex: 1 1 0;
  }
  
  h3 {
    color: var(--app-color-purple);
    font-weight: 800;
    letter-spacing: 0.095em;
    font-size: 1.6rem;
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h3, p { margin: 0 }
    button { align-self: flex-start; }
  }
  
  .graphic {
    position: relative;
    flex-grow: 1.6;
    min-height: 460px;
    width: 100%;

    .desktop {
      position: absolute;
      top: 2%;
      left: 4%;
      width: 92%;
      height: auto;
    }
    
    .mobile {
      position: absolute;
      top: 35%;
      right: 0%;
      width: 27%;
      height: auto;
    }
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    margin-bottom: 1rem;

    .graphic {
      min-height: 300px;
      max-width: 350px;

      .desktop {
        position: absolute;
        top: 5%;
        left: 6%;
        width: 80%;
        height: auto;
      }
      
      .mobile {
        position: absolute;
        top: 35%;
        right: 0%;
        width: 27%;
        height: auto;
      }
    }

  }
}


/* WhyEnDash.js */
.why-en-dash {
  width: 92vw;
  max-width: 1060px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: dense;
  align-items: flex-end;
  gap: 1rem;
  padding-bottom: 280px;
  position: relative;

  grid-template-areas: 
    'one one two'
    '. three four';


  .blurb {
    padding: 2rem;
    background-color: var(--app-color-blue);
    color: var(--app-color-teal);
    border-radius: 10px;
    align-self: flex-start;

  }
  .one {
    grid-area: one;
  }
  .two {
    grid-area: two;
  }
  .three {
    grid-area: three;
  }
  .four {
    grid-area: four;
  }
  .app-background-blob {
    top: auto;
    bottom: -4%;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
      'three three'
      'two one'
      'four one';
    padding-bottom: 200px;

  }

  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 
      'three'
      'one'
      'two'
      'four';
    padding-bottom: 100px;
  }
}

.hide-x {
  overflow-x: hidden;
}

/* A11Y */

*:focus {
  outline: 3px solid var(--app-color-teal);
  outline-offset: 3px;

  &:not(:focus-visible) {
    outline: none;
  }
}
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

$colors: 'blue', 'purple';
@each $color in $colors {
  .#{$color}-focus *:focus {
    outline-color: var(--app-color-#{$color});
  }
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}